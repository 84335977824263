@import '../variables.scss';

.NavBar {
  position: fixed;
  width: 100%;
  padding: 10px 0;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 999;

  &-placeholder {
    height: $navbar-height;
  }
}

.NavBar-link-container {
  display: flex;
  margin: 0 10px;
  align-items: center;
}

.NavBar-link-container-collapsible {
  display: none;
}

.NavBar-icon {
  padding: 4px 18px 2px;
  background: none;
  border: none;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  cursor: pointer;

  img {
    width: 68px;
  }

  &:focus {
    outline: none;
  }
}

.NavBar-link,
.NavBar-link--selected {
  color: #63757D;

  button {
    padding: 4px 18px;
    background: none;
    border: none;
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
    cursor: pointer;

    &:hover, &:focus {
      color: darken($color: #63757D, $amount: 20%);
    }

    &:focus {
      outline: none;
    }
  }
}

.NavBar-link--selected {
  color: $grey-dark;
  font-weight: bold;

  &:hover, &:focus {
    color: darken($color: $grey-dark, $amount: 20%);
  }
}

.NavBar-social-links {
  display: flex;
  align-items: center;
  margin: 0 10px;

  .NavBar-resume {
    color: $text-grey;
    text-decoration: none;
    padding: 4px 12px;
    margin: 0 10px;
    border: 1px solid $text-grey;
    border-radius: 4px;

    &:hover {
      color: darken($color: $text-grey, $amount: 20%);
      border-color: darken($color: $text-grey, $amount: 20%);
    }
  }

  .NavBar-social-link {
    color: $text-grey;
    text-decoration: none;
    margin: 0 10px;
    display: flex;
    align-items: center;

    &:hover {
      color: darken($color: $text-grey, $amount: 20%);
    }
  }
}

.NavBar-control {
  display: none;
  border-radius: 4px;
  background: none;
  border: 1px solid black;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  cursor: pointer;

  .feather-menu {
    padding: 3px 0;
  }

  &:focus {
    outline: none;
  }
}


@media only screen and (max-width: 600px) {
  .collapsed {
    max-height: 0 !important;
  }

  .NavBar-icon {
    padding-left: 12px;
  }

  .NavBar-link-container {
    .NavBar-link,
    .NavBar-link--selected {
      display: none;
    }
  }

  .NavBar-link-container-collapsible {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: $navbar-height;
    background-color: $white;
    width: 100%;
    margin: 0;
    align-items: flex-start;
    transition: max-height 0.5s ease-in-out;
    max-height: 150px;

    .NavBar-link,
    .NavBar-link--selected {
      padding: 2px 0;
    }
  }

  .NavBar-social-links {
    .NavBar-resume {
      padding: 4px 6px;
      margin: 0 5px;
    }
  }

  .NavBar-control {
    display: flex;
  }
}
