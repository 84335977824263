.About {
  background-color: #808E95;
  background-image: url('../assets/prism-light.png');
  display: flex;
}

.About-info {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  align-self: center;
  margin-left: 10vw;
  color: #FFFFFF;

  &-name {
    font-weight: bold;
  }

  &-occupation {
    font-size: 2em;
    line-height: 2em;
  }

  &-description {
    font-size: 1.5em;
    line-height: 1.6em;
  }
}

.About-image {
  max-height: 95vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-self: flex-end;

  img {
    width: 40vw;
  }
}

@media only screen and (max-width: 600px) {
  .About-image {
    display: none;
  }

  .About-info {
    margin: 0 8vw;
  }

  .About-info-name {
    padding-top: 4vh;
  }

  .About-info-occupation {
    font-size: 1.75em;
  }

  .About-info-description {
    font-size: 1.2em;
  }
}
