@import '../variables.scss';

.Skills {
  background-color: $white;
  background-image: url('../assets/background-light.png');
  background-repeat: repeat;
  display: flex;
}

.Skills-container {
  margin: 7vw;
}

.Skills-description {
  margin-top: 1vh;
  margin-bottom: 10vh;
  line-height: 1.5em;
  font-size: 1.5em;
}

.Skills-items-container {
  display: flex;
}

.SkillsItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 0 10px;

  &-icon {
    width: 10.9em;
    height: 10.9em;
    margin-bottom: 1.25em;
    background-color: $grey;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    img {
      width: 5.6em;
      height: 5.6em;
    }
  }

  &-title {
    font-size: 2em;
    line-height: 2em;
    text-align: center;
  }

  &-description {
    font-size: 1.25em;
    line-height: 1.5em;
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .Skills-items-container {
    flex-direction: column;
  }

  .Skills-description {
    font-size: 1em;
    line-height: 1.5em;
    margin-bottom: 5vh;
  }

  .SkillsItem {
    padding: 2.5vh;
    font-size: 12px;
  }
}
