@import './variables.scss';

body {
  margin: 0;
  font-family: 'Oxygen', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  font-size: 16px;
}

.App-no-overflow {
  height: 100vh;
  overflow: hidden;
}

.Section {
  width: 100%;
  min-height: calc(100vh - #{$navbar-height});

  &-Primary {
    background-color: #808E95;
    color: #FFFFFF;
  }

  &-Secondary {
    background-color: #FFFFFF;
    color: #808E95;
  }

  &-header {
    font-size: 4.5em;
  }
}

.ScrollMarker {
  position: relative;
  top: -$navbar-height;
}

@media only screen and (max-width: 600px) {
  .Section-header {
    font-size: 2.75em;
  }
}
