@import '../variables.scss';

.Projects {
  display: flex;
  flex-direction: column;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%239C92AC' fill-opacity='0.02' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.Projects-container {
  margin: $section-margins;
  display: flex;
  flex-direction: column;
}

.Projects-items-container {
  margin: 2vh $section-margins;
}

.ProjectItem {
  display: flex;
  margin: 10px 0;
  padding: 10px 20px;
  color: inherit;
  text-decoration: none;

  &:hover, &:focus {
    -webkit-box-shadow: 0px 0px 12px 1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 12px 1px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 12px 1px rgba(0,0,0,0.75);
    border-radius: 10px;
    outline: none;
  }
}

.ProjectItem-image-desktop {
  display: flex;
  align-items: center;

  img {
    max-width: 12vh;
    max-height: 12vh;
  }
}

.ProjectItem-image-mobile {
  display: none;

  img {
    max-width: 12vw;
  }
}

.ProjectItem-details {
  margin-left: 3vw;
  display: flex;
  flex-direction: column;

  .ProjectItem-header {
    .ProjectItem-title {
      font-size: 2em;

      .ProjectItem-tools {
        font-size: 0.5em;
      }
    }

    margin-bottom: 4px;
  }

  .ProjectItem-description-item {
    margin-top: 6px;
    line-height: 22px;
  }
}

.Blinds-image {
  border-radius: 50%;
}

@media only screen and (max-width: 600px) {
  .Projects-container {
    margin: $section-margins;
  }

  .Projects-items-container {
    margin: 2vh 0;
  }

  .ProjectItem {
    padding: 10px;
  }

  .ProjectItem-details {
    font-size: 12px;
    margin-left: 0;

    .ProjectItem-header {
      display: flex;
    }
  }

  .ProjectItem-image-desktop {
    display: none;
  }

  .ProjectItem-image-mobile {
    display: flex;
    align-items: center;
    margin-top: 2px;
    margin-right: 6px;

    img {
      max-width: 10vw;
    }
  }
}
