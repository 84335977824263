@import '../variables.scss';

.Experience {
  position: relative;
  display: flex;
  overflow: hidden;
  background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.02'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");  background-repeat: repeat;

  .Experience-background {
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    background-color: $white;
    background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23dedddf' fill-opacity='0.1'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");  }
}

.Experience-container {
  margin: $section-margins;
  display: flex;
  flex-direction: column;
}

.Experience-items-container {
  margin-top: 20px;
}

.Experience-row {
  display: flex;
  flex-direction: row;
}

.ExperienceItem {
  z-index: 1;
  flex: 1;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-right-align {
    text-align: right;
  }

  &-on-right {
    margin-left: 40px;
    margin-right: 0;
    color: $text-grey;
  }
}

.ExperienceItem-header {
  font-size: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ExperienceItem-company-name {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: $grey-dark;
      text-decoration: underline;
    }

    .feather-external-link {
      margin-left: 10px;
    }
  }

  .ExperienceItem-date {
    font-size: 16px;
  }
}

.ExperienceItem-job-title {
  font-size: 22px;
  margin-top: 12px;
  margin-bottom: 8px;
}

.ExperienceItem-description {
  font-size: 18px;
  line-height: 28px;
}

.ExperienceItem-description-item {
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.ExperienceItem-divider {
  display: none;
  margin: 2vw 10vw;
  border:1px solid $grey-light;

  &-right {
    border:1px solid $grey-dark;
  }
}

.ExperienceItem-divider-show {
  display: block;
}

@media only screen and (max-width: 600px) {
  .Experience {
    .Experience-background {
      display: none;
    }
  }

  .Experience-row {
    flex-direction: column;
  }

  .ExperienceItem {
    margin: 0;

    &-on-right {
      margin: 0;
      color: inherit;
    }
  }

  .ExperienceItem-header {
    font-size: 1.8em;
  }

  .ExperienceItem-divider {
    display: block;
    margin: 3vh 10vw;
    border:1px solid $grey-light;

    &-right {
      border:1px solid $grey-light;
    }

    &-last {
      display: none;
    }
  }
}
