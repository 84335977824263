$white: #FFFFFF;

$grey-dark: #405763;
$grey: #808E95;
$grey-light: #DADFE2;

$text-grey: #4C626D;

$section-margins: 7vw;

$navbar-height: 58px;
