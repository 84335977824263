.Intro {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  z-index: 10000;
  align-items: center;
  justify-content: center;
  background-color: white;
  opacity: 1;

  img {
    height: 9vh;
  }
}

.Intro--faded {
  opacity: 0;
  transition: opacity 0.5s linear;
}
